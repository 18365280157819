import { Layout, Row, Col, Tabs, message, Button, Space } from "antd";
import Icon, { SyncOutlined } from "@ant-design/icons";
import Header from "../../components/header/header";
import SubMenu from "../../components/submenu";
import Cards from "./cards";
import Table from "./table";
import Chart from "./charts";

import "./layout.css";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";
import { useEffect, useState } from "react";
import { downloadXls } from "./excelDownload";

const { Content, Footer } = Layout;
const { TabPane } = Tabs;

const colorList = [
  "#ff7f50",
  "#87cefa",
  "#da70d6",
  "#32cd32",
  "#6495ed",
  "#ff69b4",
  "#ba55d3",
  "#cd5c5c",
  "#ffa500",
  "#40e0d0",
  "purple",
  "red",
];

const LayoutComponent = (history) => {
  const [riskRevenue, setRiskRevenue] = useState(0);
  const [highestOpp, setHighestOpp] = useState(0);
  const [reducedRisk, setReducedRisk] = useState(0);
  const [highestFlightRisk, setHighestFlightRisk] = useState([]);
  const [searchFlightRisk, setSearchFlightRisk] = useState([]);
  const [payload, setPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [selectedChurn, setSelectedChurn] = useState([]);
  const [defaultTabKey, setDefaultTabKey] = useState("1");
  const [sync, setSync] = useState(false);
  const [page, setPage] = useState(1);

  const handleGetData = (data) => {
    setPayload(data);
    setDefaultTabKey("1");
    getRevenueRisk(data);
    getHighestOpportunity(data);
    getReducedChurnRisk(data);
    // getFlightRisk({ ...data, page: page, rowsPerPage: 10 });
    getFlightRisk(data);
  };

  const handleGetFlightRisk = (data) => {
    console.log(data, "handleGetFlightRisk");
    setPayload(data);
    setDefaultTabKey("1");
    getRevenueRisk(data);
    getHighestOpportunity(data);
    getReducedChurnRisk(data);
    // getFlightRisk({ ...data, page: page, rowsPerPage: 10 });
    getFlightRisk(data);
  };

  const getFlightRisk = async (data) => {
    console.log(data, "payload")
    try {
      setLoading(true);
      const apiUrl = `${BASE_URL}/churnAnalysis/getHighestFlightRisk`;

      axios
        .post(apiUrl, data)
        .then((response) => {
          const resp = response?.data || "";
          setHighestFlightRisk(resp);
          setSearchFlightRisk(resp);
          setLoading(false);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getRevenueRisk = async (data) => {
    try {
      setLoading(true);
      const apiUrl = `${BASE_URL}/churnAnalysis/revenueAtRisk`;
      axios
        .post(apiUrl, data)
        .then((response) => {
          const resp = response?.data || "";
          setRiskRevenue(resp[0].revenue_at_risk);

          // setLoading(false);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getHighestOpportunity = async (data) => {

    try {
      // setLoading(true);
      const apiUrl = `${BASE_URL}/churnAnalysis/highestOpportunities`;

      axios
        .post(apiUrl, data)
        .then((response) => {
          const resp = response?.data || "";
          setHighestOpp(resp[0].highest_opportunities);

          // setLoading(false);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getReducedChurnRisk = async (data) => {
    console.log("Data", data)
    const {churnLabel,dealer,market,product,region, store,toYear,year  } = data
    const payload = {
     toYear : year,
     fromYear:year -1,
     churnLabel,
     dealer,
     market,
     product,
     region,
     store,
    }
    try {
      setLoading(true);
      const apiUrl = `${BASE_URL}/churnAnalysis/reducedRevenueRisk`;

      axios
        .post(apiUrl, payload)
        .then((response) => {
          const resp = response?.data || "";
          setReducedRisk(resp[0].Reduced_Revenue_Risk);
          // setLoading(false);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const handleSearchCustomer = (name) => {
    const data = searchFlightRisk.filter((item) => {
      return item.customerNumber.toLowerCase().includes(name.toLowerCase());
    });
    setHighestFlightRisk(data);
  };

  const handleTabChange = (key) => {
    if (key === "2") {
      setDefaultTabKey("2");
      getRevenueRisk(payload);
      getHighestOpportunity(payload);
      getReducedChurnRisk(payload);
      // getFlightRisk({
      //   ...payload,
      //   year: payload.year - 1,
      //   page: page,
      //   rowsPerPage: 10,
      // });
      getFlightRisk({ ...payload, year: payload.year - 1 });
    } else {
      setDefaultTabKey("1");
      getRevenueRisk(payload);
      getHighestOpportunity(payload);
      getReducedChurnRisk(payload);
      // getFlightRisk({ ...payload, page: page, rowsPerPage: 10 });
      getFlightRisk(payload);
    }
  };

  const handleDownloadExcel = () => {
    console.log("highestFlightRisk", highestFlightRisk)

    downloadXls(
      [highestFlightRisk,  [{ "Revenue At Risk": riskRevenue, "Highest Opportunities": highestOpp, "Reduced Churn Risk" :reducedRisk }]],
      `Highest_Flight_Risk_${payload.year}`,
      ["HighestFlightRisk", "KPI's"]
    );
  };
  const generateColor = () => {
    return "#" + Math.random().toString(16).substr(-6);
  };

  const plotByColor = (selectedFilter = [], item) => {
    let array = [];
    // eslint-disable-next-line array-callback-return
    selectedFilter.map((store) => {
      const color = generateColor();
      const flightRisk = highestFlightRisk.filter((x) => x[item] === store);
      const tempData = flightRisk.map((x) => {
        return { ...x, color: color };
      });
      array.push(...tempData);
    });
    setHighestFlightRisk(array);
  };

  const handleColorBy = (value) => {
    if (value === "1") {
      plotByColor(selectedRegion, "region");
    } else if (value === "2") {
      plotByColor(selectedProduct, "product");
    } else if (value === "3") {
      plotByColor(selectedStore, "store");
    } else if (value === "4") {
      plotByColor(selectedMarket, "market");
    } else if (value === "5") {
      plotByColor(selectedChurn, "clusterLabel");
    } else {
      const data = highestFlightRisk.map((x) => {
        return { ...x, color: "#0a6fb8" };
      });
      setHighestFlightRisk(data);
    }
  };

  const handleSelectedStore = (values) => {
    setSelectedStore(values);
  };

  const handleSelectedRegion = (values) => {
    setSelectedRegion(values);
  };

  const handleSelectedProduct = (values) => {
    setSelectedProduct(values);
  };

  const handleSelectedMarket = (values) => {
    setSelectedMarket(values);
  };

  const handleSelectedChurn = (values) => {
    setSelectedChurn(values);
  };

  const handleDBSync = async () => {
    try {
      await setSync(true);
      const apiUrl = `${BASE_URL}/churnAnalysis/updateFlatTables`;
      axios
        .post(apiUrl)
        .then(async (response) => {
          const resp = response?.data || "";
          await setSync(false);
        })
        .catch((error = "") => {
          setSync(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error({
            content: "Something went wrong",
            style: {
              marginTop: "15vh",
            },
          });
        });
    } catch (error) {
      setSync(false);
      message.error({
        content: "Something went wrong",
        style: {
          marginTop: "15vh",
        },
      });

      throw new Error({ error });
    }
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };
console.log(history)
  // useEffect(() => {
  //   if (page !== 0 && JSON.stringify(payload) !== JSON.stringify({})) {
  //     getFlightRisk({ ...payload, page: page, rowsPerPage: 10 });
  //   }
  // }, [page]);

  return (
    <Layout className="layout">
      <Header  />
      <div className="logo" />

      <Content>
        <SubMenu
          selectedStore={handleSelectedStore}
          selectedRegion={handleSelectedRegion}
          selectedProduct={handleSelectedProduct}
          selectedMarket={handleSelectedMarket}
          selectedChurn={handleSelectedChurn}
          onHandleSearchCustomer={handleSearchCustomer}
          onHandleDownloadExcel={handleDownloadExcel}
          header="Revenues At-risks"
          onHandleGetData={handleGetData}
          onHandleGetFlightRisk={handleGetFlightRisk}
          highestFlightRisk={highestFlightRisk}
          extraButton={
            <Row>
              <Button href="/segmentFlight" className="compare" type="primary">
              Changes in Revenues At-Risks
              </Button>
              <Button disabled = {sync} style={{ marginLeft: 5 }} onClick={handleDBSync}>
                <Space>
                  <SyncOutlined spin={sync} />
                </Space>
              </Button>
            </Row>
          }
        />
        <div className="site-layout-content">
          <Row>
            <Col span={12}>
              <Cards
                riskRevenue={riskRevenue}
                loading={loading}
                highestOpp={highestOpp}
                reducedRisk={reducedRisk}
              />
              <Chart
                onHandleColorBy={handleColorBy}
                highestFlightRisk={highestFlightRisk}
                loading={loading}
              />
            </Col>
            <Col span={11} offset={1}>
              <Tabs
                type="card"
                onChange={(event) => handleTabChange(event)}
                key={defaultTabKey}
                defaultActiveKey={defaultTabKey}
              >
                <TabPane tab="Highest Flight Risk" key="1">
                  <div className="tableWrapper">
                    <Table
                      highestFlightRiskData={highestFlightRisk}
                      loading={loading}
                    />
                  </div>
                  {/* <div className="tablePagination">
                    <div>
                      <Button
                        disabled={page === 1}
                        onClick={handlePreviousPage}
                      >
                        <LeftOutlined />
                      </Button>
                      <span className="pagination">{page}</span>
                      <Button
                        onClick={handleNextPage}
                        disabled={highestFlightRisk.length === 0}
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div> */}
                </TabPane>
                <TabPane tab="Past Flight Risk" key="2">
                  <div className="tableWrapper">
                    <Table
                      highestFlightRiskData={highestFlightRisk}
                      loading={loading}
                    />
                  </div>
                  {/* <div className="tablePagination">
                    <div>
                      <Button
                        disabled={page === 1}
                        onClick={handlePreviousPage}
                      >
                        <LeftOutlined />
                      </Button>
                      <span className="pagination">{page}</span>
                      <Button
                        onClick={handleNextPage}
                        disabled={highestFlightRisk.length === 0}
                      >
                        <RightOutlined />
                      </Button>
                    </div>
                  </div> */}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Content>
      {/* <Footer style={{ textAlign: "center" }}>2021 © 360CX.ai</Footer> */}
    </Layout>
  );
};

export default LayoutComponent;


