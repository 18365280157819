/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import Styles from "./footer.module.css";

class Footer extends Component {
  state = { previewVisible: false };

  hideModal = () => {
    this.setState({ previewVisible: false });
  };

  render() {
    return (
      <>
        <div className={Styles.footer}>
          <ul>
            <li>
              <a href="">© 360CX.ai 2021</a>
            </li>
            <li>Terms & Conditions</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
      </>
    );
  }
}

export default Footer;
