import { lazy } from "react";
const DashBoard = lazy(() => import("../../pages/views/Dashboard"));
const Tickets = lazy(() => import("../../pages/views/Tickets"));
const Profile = lazy(() => import("../../pages/views/Profile"));
const Settings = lazy(() => import("../../pages/views/Settings"));
const Layout = lazy(() => import("../../pages/layout"));
const SegmentFlight = lazy(() =>
  import("../../pages/layout/segmentFlightRisk/segmentFlightRisk")
);
const Actions = lazy(() => import("../../pages/layout/actions/actions"));
const CustomerComparison = lazy(() =>
  import("../../pages/customer-comparsion/comparisionDashboard")
);
const Nurturing = lazy(() => import("../../pages/Nurturing/nurturing"));
const ScoreCard = lazy(() => import("../../pages/layout/scoreCard/scoreCard"));
const CustomerJourney = lazy(() =>
  import("../../pages/layout/customerJourney/customerJourney")
);

export {
  DashBoard,
  Tickets,
  Profile,
  Settings,
  Layout,
  SegmentFlight,
  Actions,
  CustomerComparison,
  Nurturing,
  ScoreCard,
  CustomerJourney,
};
