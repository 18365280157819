/* eslint-disable react/jsx-no-duplicate-props */
import React, { PureComponent } from "react";
import { Menu, Layout, Avatar, message } from "antd";
import {
  BellOutlined,
  SettingOutlined,
  DashboardOutlined,
  ProfileOutlined,
  ClockCircleOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import axios from "axios";
import jwt_decode from "jwt-decode";

import { churnData, NurturingData, Role } from "../../constants/nav";
import auth from "../../utils/auth";
import logo from "../../assets/images/cxLogo.png";
import { getToken } from "../../constants/constants";
import style from "./header.module.css";
import { BASE_URL } from "../../constants/constants";

const { Header } = Layout;
const { SubMenu } = Menu;
const navigation = window.location;
class HeaderMenu extends PureComponent {
  constructor(props) {
    super(props);
    const { match = "" } = props || "";
    const { path = "" } = match;
    const whichPageClicked = path.split("/")[1] || "dashboard";
    this.state = { whichPageClicked, moduleSelected: "", screens: [], selectedScreen: "" };
  }

  componentDidMount() {
    const decoded = jwt_decode(getToken());

    console.log("decoded", decoded)
    this.getAllModules();
    const path = window.location?.pathname
    switch (path) {
      case "/layout":
        this.setState({
          moduleSelected: "Churn Risk",
          selectedScreen: "Revenues At-risks"
        });
        break;
      case "/segmentFlight":
        this.setState({
          moduleSelected: "Churn Risk",
          selectedScreen: "Changes in Revenues At-Risks"
        });
        break;
      case "/customerComparison":
        this.setState({
          moduleSelected: "Churn Risk",
          selectedScreen: "Compare"
        });
        break;
      case "/action":
        this.setState({
          moduleSelected: "Churn Risk",
          selectedScreen: "Root Cause and Actions"
        });
        break;
      case "/customerScore":
        this.setState({
          moduleSelected: "Churn Risk",
          selectedScreen: "Customer Scorecard"
        });
        break;
      case "/nurturing":
        this.setState({
          moduleSelected: "Nurturing",
          selectedScreen: "MapView"
        });
        break;
        case "/customerJourney":
          this.setState({
            moduleSelected: "Nurturing",
            selectedScreen: "CX Journey"
          });
          break;
      default:
        this.setState({
          moduleSelected: "Churn Risk",
          selectedScreen: this.state.screens[0]
        });
        break;
    }
  }

  getAllModules = async () => {
    try {
      const response = await axios.get(`getUserModuleMapping?userId=14`);
      const { userModuleDTOS } = response?.data || "";
      console.log("userModuleDTOS", userModuleDTOS)
      this.getModuleMenu(userModuleDTOS);
      return "";
    } catch (err) {
      this.getModuleMenu();
      return "";
    }
  };

  getModuleMenu = (arr = []) => {
    const userModuleDTOS =
      arr.length > 0
        ? arr
        : [
          { moduleName: "Churn Risk", moduleId: 1 },
          { moduleName: "Nurturing", moduleId: 2 },
        ];

    const modulesArray = userModuleDTOS.map(({ moduleName, moduleId }) => (
      <Menu.Item
        key={moduleName}
        value={moduleId}
        onClick={(e) => this.moduleChange(e)}
      >
        {moduleName}
      </Menu.Item>
    ));
    this.setState(
      {
        modulesArray,
        // moduleSelected: userModuleDTOS[0]?.moduleName || "Churn",
      },
      this.getScreenMenu
    );
  };
  getScreenMenu = () => {
    const { moduleSelected } = this.state;
    console.log("moduleSelected", moduleSelected)
    // const selectedModule = localStorage.getItem("module")

    const navArray = moduleSelected === "Churn Risk" ? churnData : NurturingData;
    console.log("cx", navArray)

    const screens = navArray.map(({ navTitle, route }) => (
      <Menu.Item
        key={route}
        disabled = {!route}
        // onClick={() => this.props.history.push("/layout")}
        onClick={() => {


          navigation.replace(`${route}`);
        }
        }
      >
        {navTitle}
      </Menu.Item>
    ));
    this.setState({ screens });
  };

  logout = (e = "") => {
    if (e.key === "logout") {
      auth.removeToken();
      if (auth.getToken()) {
        auth.removeToken();
        localStorage.clear()
    }
      // auth0Client.signOut();
      navigation.replace("/login");
    }
  };

  navigateTo = (gotTo = "dashboard") => {
    console.log("nurturing", gotTo, this.props)
    this.setState(
      { whichPageClicked: gotTo },
      // this.props.history.replace(`/${gotTo}`)
    );
    navigation.replace(`/${gotTo}`)
  };

  getActiveStyle = (path = "dashboard", via = 1) => {
    const { whichPageClicked = "dashboard" } = this.state;
    const iconColor = via === 1 ? {} : { color: "#fff" };
    const style = whichPageClicked === path ? { color: "orange" } : iconColor;
    return style;
  };

  moduleChange = ({ key = "Churn" }) => {
    console.log(key,"key")
    // localStorage.setItem("module", key)
    this.setState({ moduleSelected: key }, this.getScreenMenu);
  }
   getMenuData = (data) => {
     if(data?.key === "8"){
      try {

        const apiUrl = `${BASE_URL}churnAnalysis/truncateAllTables`;
        axios
            .post(apiUrl,)
            .then((response) => {
                message.success("Data Cleared Successfully", 4)

            })
            .catch((error = "") => {
              message.error("Failed to Clear the Data", 4)
            });
    } catch (error) {

    }
     }
  }
  render() {
    const { navigateTo, getActiveStyle, state } = this;
    const { screens, modulesArray } = state;
    console.log(this.state.selectedScreen)

    return (
      <Header
        className="site-layout-background"
        style={{
          padding: 0,
          position: "fixed",
          // zIndex: 10000000000000000000,
          width: "100%",
        }}
        className={style.menuHeader}
      >
        <Menu
          size="small"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="horizontal"
          className={style.leftMenu}
        >
          <SubMenu
            key="logoApp"
            icon={
              <img
                src={logo}
                alt="logo"
                style={{ width: 150, height: 40 }}
              />
            }
            title=""
            // onTitleClick={() => navigateTo("dashboard")}
          />
        </Menu>

        <Menu
          size="small"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="horizontal"
          className={style.leftMenu}
          onClick= {this.getMenuData}
        >
          <SubMenu
            style={getActiveStyle("dashboard", 1)}
            key="DashBoard"
            icon={
              <DashboardOutlined
                style={({ color: "#353333" }, getActiveStyle("dashboard", 2))}
              />
            }
            title={this.state.selectedScreen }
          // onTitleClick={() => navigateTo("nurturing")}
          >
            {screens}
          </SubMenu>

          {/* <SubMenu
            style={getActiveStyle("profile", 1)}
            key="Profile"
            icon={<ProfileOutlined style={getActiveStyle("profile", 2)} />}
            title="Profile"
            onTitleClick={() => navigateTo("profile")}
          />
          <SubMenu
            style={getActiveStyle("tickets", 1)}
            key="Tickets"
            icon={<ClockCircleOutlined style={getActiveStyle("tickets", 2)} />}
            title="Tickets"
            onTitleClick={() => navigateTo("tickets")}
          />
          <SubMenu
            style={getActiveStyle("settings", 1)}
            key="Settings"
            icon={<ToolOutlined style={getActiveStyle("settings", 2)} />}
            title="Settings"
            onTitleClick={() => navigateTo("settings")}
          /> */}
          <SubMenu
            key="Notification"
            icon={<BellOutlined style={{ color: "#353333" }} />}
            title="Notification"
          />

          <SubMenu
            key="sub2"
            icon={<SettingOutlined style={{ color: "#353333" }} />}
            title={this.state.moduleSelected}
          >
            {modulesArray}
          </SubMenu>

          <SubMenu
            key="2"
            icon={
              <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
                U
              </Avatar>
            }
          >
            <Menu.Item key="7">{Role}</Menu.Item>
            <Menu.Item key="8">Clear Data</Menu.Item>
            <Menu.Item key="9">Account Settings</Menu.Item>
            <Menu.Item key="logout" onClick={(e) => this.logout(e)}>
              Sign Out
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    );
  }
}

export default HeaderMenu;