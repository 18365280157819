/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Alert, Spin, Row, message } from "antd";
import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import auth0Client from "../../auth0/Auth";
import auth from "../../utils/auth";
import Styles from "../../styles/auth.module.css";
const navigation = window.location;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);

const messageStyle = { textAlign: "center", fontWeight: 600 };

const layout = {
  labelCol: { span: 24 },
};

const LoginFooter = () => (
  <div className="login-footer">
    <LockOutlined style={{ fontSize: 16, color: "#8692a6" }} />
    <h4>Your Info is safely secured</h4>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loggingIn: false,
    };
  }

  handleSubmit = async (e) => {
    const { emailId, password } = e;
    const { generateToken } = this.props;
    // auth.removeToken();
    this.setState({ errorMessage: null, loggingIn: true });
    try {
      // const response = await auth0Client.manualLogin(emailId, password);
      const response = await generateToken(emailId, password);
      console.log(response);
      if (response.access_token && response.access_token !== "") {
        message.success(`Welcome To 360CX`, 1);
        setTimeout(() => {
          navigation.replace("/layout");
        }, 1000);
      } else {
        this.setState({
          errorMessage: response.errorMessage || "Invalid credentials",
          loggingIn: false,
        });
      }
    } catch (error) {
      this.setState({ loggingIn: false });
      throw new Error({ error });
    }
  };

  onChange = () => {
    this.setState({ errorMessage: null });
  };

  render() {
    const { errorMessage, loggingIn } = this.state;

    return (
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100%",
        }}
      >
        <div
          className={Styles.loginForm}
          style={{
            padding: "30px 45px",
            width: "50%",
          }}
        >
          <label className={Styles.mainLabelLogin}>Login to your account</label>
          {errorMessage && (
            <Alert message={errorMessage} type="error" style={messageStyle} />
          )}
          <Form
            initialValues={{
              remember: true,
            }}
            {...layout}
            requiredMark={"optional"}
            name="login"
            onFinish={this.handleSubmit}
          >
            <Form.Item
              labelCol={{ span: 24 }}
              name="emailId"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter valid Email id",
                },
              ]}
              onChange={this.onChange}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
              onChange={this.onChange}
            >
              <Input type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
                <Link className={Styles.loginFormForgot} to="/forget-password">
                  Reset password?
                </Link>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className={Styles.loginFormButton}
              >
                {loggingIn ? (
                  <Spin
                    indicator={antIcon}
                    size="large"
                    style={{ fontSize: 20 }}
                  />
                ) : (
                  "LOGIN"
                )}
              </Button>
            </Form.Item>
          </Form>
          <LoginFooter />
          <Row justify="center" align="middle">
            <span className={Styles.registerRow}>
              Don’t have an account?
              <a
                onClick={() => this.props.history.push("/register")}
                className={Styles.register}
              >
                {" "}
                Register
              </a>
            </span>
          </Row>
        </div>
      </Row>
    );
  }
}

export default LoginForm;
