const ADMIN = 1;
const SERVICE_PROVIDER = 2;
const AUDITOR = 3;
const CUSTOMER_CARE = 4;

const DASHBOARD = {
  component: "DashBoard",
  url: "/dashboard",
};

const PROFILE = {
  component: "Profile",
  url: "/profile",
};

const TICKETS = {
  component: "Tickets",
  url: "/tickets",
};

const SETTINGS = {
  component: "Settings",
  url: "/settings",
};

const LAYOUT = {
  component: "Layout",
  url: "/layout",
};

const SEGMENT_FLIGHT = {
  component: "SegmentFlight",
  url: "/segmentFlight",
};

const ACTIONS = {
  component: "Actions",
  url: "/action",
};

const LOGOUT = {
  component: "Logout",
  url: "/logout",
};

const CUSTOMERCOMPARISON = {
  component: "CustomerComparison",
  url: "/customerComparison",
};

const Nurturing = {
  component: "Nurturing",
  url: "/nurturing",
};
const SCORECARD = {
  component: "ScoreCard",
  url: "/customerScore",
};

const CUSTOMERJOURNEY = {
  component: "CustomerJourney",
  url: "/customerJourney",
};

const COMMON_ROUTES = [
  { ...LOGOUT },
  { ...DASHBOARD },
  { ...PROFILE },
  { ...TICKETS },
  { ...SETTINGS },
  { ...LAYOUT },
  { ...SEGMENT_FLIGHT },
  { ...ACTIONS },
  { ...CUSTOMERCOMPARISON },
  { ...Nurturing },
  { ...SCORECARD },
  { ...CUSTOMERJOURNEY },
];

const ADMIN_LOGIN = {
  component: "Admin",
  url: "/configurations",
};

const SUPER_ADMIN_ROUTES = [...COMMON_ROUTES, DASHBOARD, ADMIN_LOGIN];
const SUPER_USER_ROUTES = [...COMMON_ROUTES, DASHBOARD, ADMIN_LOGIN];

export {
  ADMIN,
  AUDITOR,
  CUSTOMER_CARE,
  SERVICE_PROVIDER,
  SUPER_ADMIN_ROUTES,
  SUPER_USER_ROUTES,
  COMMON_ROUTES,
  DASHBOARD,
  ACTIONS,
  SCORECARD,
  CUSTOMERJOURNEY,
};
