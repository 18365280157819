/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Select } from "antd";
// { options = [], onChangeFn }
const MultiSelect = (props) => {
  const [value, setValue] = useState([]);
  const { options = [], onChangeFn, defaultValue, placeholder } = props;

  const onSelection = (e = "") => {
    const objID = e.toString();
    const data = Object.keys(e).length > 0 ? objID.split(",") : [];
    if (data.includes("All")) {
      const selected = options.map((item) => item.value);

      setValue(selected);
      onChangeFn(selected);
    } else {
      onChangeFn(data);
      setValue([]);
    }
  };

  useEffect(() => {
    const optionTemp =
      defaultValue && defaultValue.length > 0 ? defaultValue : [];

    setValue(optionTemp);
    if (optionTemp.length > 0) {
      onChangeFn(optionTemp);
    }
  }, [defaultValue]);

  const selectProps = {
    mode: "multiple",
    className: "adminMultiSelect",
    placeholder,
    maxTagCount: "responsive",
    showSearch: true,
    showArrow: true,
    allowClear: true,
    style: {
      width: "100%",
    },
    value,
    options,
    onChange: (newValue) => {
      setValue(newValue);
      onSelection(newValue);
    },
    filterOption: (input = "", option = "") =>
      option.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0,
  };
  return <Select {...selectProps} />;
};

export default MultiSelect;
