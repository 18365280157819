import { getToken } from "../../src/constants/constants";
import jwt_decode from "jwt-decode";

const churnData = []
const NurturingData = []
let Role = undefined;
// const token = localStorage.getItem("token")
if (getToken()) {
  const decoded = jwt_decode(getToken());
  const { screens } = decoded
  Role = decoded?.roles
  for (const property in screens) {
    if (screens[property]) {
      if (property === "revenue_risk_summary") {
        churnData.unshift({ navTitle: "Revenues At-risks", route: "/layout" })
      }
      if(property === "segment_flight_risk"){
        churnData.push({ navTitle: "Changes in Revenues At-Risks", route: "/segmentFlight" })
      }
      if(property === "customer_comparison"){
        churnData.push({ navTitle: "Compare", route: "/customerComparison" })
      }
      if(property === "churn_analysis"){
        churnData.push({ navTitle: "Root Cause and Actions", route: "/action" })
      }
      if(property === "customer_score_cards"){
        churnData.push({ navTitle: "Customer Scorecard", route: "/customerScore" },
        )
      }
      if(property === "nurturing"){
        NurturingData.push(
          { navTitle: "MapView", route: "/nurturing" },
          { navTitle: "CX Journey", route:"/customerJourney"  }
        )
      }
    }
  }
}

// console.log("churnData", churnData)
// const NAV = {
//   cx: [
//     { navTitle: "Map View", route: "/nurturing" },
//     { navTitle: "Customer Journey", route:"/customerJourney"  }
//   ],
//   churn: [
//     { navTitle: "Revenue Risk Summary", route: "/layout" },
//     { navTitle: "Segment Flight Risk", route: "/segmentFlight" },
//     { navTitle: "Customer Comparision", route: "/customerComparison" },
//     { navTitle: "Actions", route: "/action" },
//     { navTitle: "Customer Score Card", route: "/customerScore" },
//   ],
// };

export { churnData, NurturingData, Role};
