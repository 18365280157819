import { message } from "antd";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const EXCEL_FILE_EXTENSION = ".xlsx";
/* eslint-disable import/prefer-default-export */

const success = () => {
  message
    .loading(
      {
        content: "Download in progress...",
        style: {
          marginTop: "20vh",
        },
      },
      1
    )
    .then(() => message.success("Downloading finished", 1));
};

const failure = (msg) => {
  message.error("Download Failed", 1).then(() => message.warning(msg, 1.5));
};

const fetchSenetenceCase = (value) => {
  const text = value;
  const result = text?.replace(/([A-Z])/g, " $1");
  const finalResult = result?.charAt(0)?.toUpperCase() + result?.slice(1);
  return finalResult;
};

export const downloadXls = (array, fileName , sheetName) => {
  try {
    const xlsxData = []
      array.forEach((item) => {
        const formattedTableData =item?.map((reportData = {}) => {
          const newObj = {};
          Object.entries(reportData).forEach(([key, value]) => {
            if (key !== "key") {
              const newFormattedKey = fetchSenetenceCase(key);
              newObj[newFormattedKey] = value;
            }
          });
          return newObj;
        });
        xlsxData.push({ws:XLSX.utils.json_to_sheet(formattedTableData)})
         
      })
 

    
   

      const obj = {}
      sheetName.forEach((item, index) => {
        obj[item]  = xlsxData[index]['ws']
        
      })
      const wb = {
        Sheets: obj,
        SheetNames: sheetName,
      };

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: EXCEL_FILE_TYPE });
      message
        .loading(
          {
            content: "Download in progress...",
            style: {
              marginTop: "20vh",
            },
          },
          1
        )
        .then(() => {
          FileSaver.saveAs(data, fileName + EXCEL_FILE_EXTENSION);
          message.success("Downloading finished", 1);
        });
    //  else {
    //   message.error("No data to download");
    // }
  } catch (err) {
    failure("Please try again");
  }
};
