import { Spin, Table } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as RedDown } from "../../assets/images/redDown.svg";
import { ReactComponent as GreenUp } from "../../assets/images/greenUp.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const data = [
  {
    key: "1",
    name: "Customer 1",
    age: 32,
    address: "Store | Region",
  },
  {
    key: "2",
    name: "Customer 2",
    age: 42,
    address: "Store | Region",
  },
  {
    key: "3",
    name: "Customer 3",
    age: 32,
    address: "Store | Region",
  },
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

export default function TableComponent(props) {
  const { highestFlightRiskData, loading } = props;
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState(highestFlightRiskData);

  useEffect(() => {
    if (highestFlightRiskData.length > 0) {
      const data = highestFlightRiskData.map((item) => ({
        ...item,
        currentYearCompare:
          item.churnRiskCurrentYear < item.churnRiskPreviousYear,
      }));
      setDataSource(data);
      const columns = [
        {
          title: "Customer Name",
          dataIndex: "customerNumber",
          sorter: (a, b) => a.customerNumber.localeCompare(b.customerNumber),
          render: (text) => (
            <Link
              to={{
                pathname: "/customerScore",
                state: { customer: text },
              }}
            >
              {text}
            </Link>
          ),
        },
        {
          title: "Current Risk",
          dataIndex: "churnRiskCurrentYear",
          align: "center",
          sorter: (a, b) => a.churnRiskCurrentYear - b.churnRiskCurrentYear,
          render: (_s, churnRisk) => (
            <div className="table-numeric">
              <span
                style={
                  churnRisk.churnRiskCurrentYear && churnRisk.currentYearCompare
                    ? { color: "#82c250" }
                    : { color: "#f25050" }
                }
              >
                {churnRisk.churnRiskCurrentYear} %
              </span>
            </div>
          ),
        },
        {
          title: "Change In Churn Risk",
          dataIndex: "currentYearCompare",
          align: "center",
          sorter: (a, b) => a.currentYearCompare - b.currentYearCompare,
          render: (currentYearCompare) => (
            <div className="table-numeric">
              <span>
                {!currentYearCompare && (
                  <Icon style={{ padding: "5px" }} component={RedDown} />
                )}
                {currentYearCompare && (
                  <Icon style={{ padding: "5px" }} component={GreenUp} />
                )}
              </span>
            </div>
          ),
        },
        {
          title: "Previous Risk",
          dataIndex: "churnRiskPreviousYear",
          align: "center",
          sorter: (a, b) => a.churnRiskPreviousYear - b.churnRiskPreviousYear,
          render: (churnRisk) => (
            <span
              style={
                // churnRisk
                { color: "#f25050", padding: "3px" }
                // : { color: "#82c250", padding: "3px" }
              }
            >
              {churnRisk !== "null" ? churnRisk + " %" : "N.A"}
            </span>
          ),
        },
      ];
      setColumns(columns);
    } else {
      setDataSource([]);
    }
  }, [highestFlightRiskData]);

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        pagination={false}
        scroll={{ y: 414 }}
      />
    </Spin>
  );
}
