import { Card, Col, Row, Skeleton, Space } from "antd";

import "./layout.css";
const Cards = (props) => {
  const { loading, highestOpp, riskRevenue, reducedRisk } = props;

  return (
    <div className="site-card-wrapper">
      <Row gutter={16}>
        <Col span={8}>
          <Card
            style={{
              background: "#e9f6ff",
              borderColor: "#bae2fe",
              borderRadius: 5,
              borderLeft: "5px solid rgb(10, 111, 184)",
            }}
          >
            <b>Revenue At-Risk</b>

            {loading ? (
              <Space>
                <Skeleton.Input style={{ width: 150, height: 20 }} active />
              </Space>
            ) : (
              <div className="kpi">
                <b>
                  {riskRevenue
                    ? "£ " +
                      riskRevenue
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    : "N.A"}
                </b>
              </div>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              background: "#fff8e7",
              borderColor: "#ed9c22",
              borderRadius: 5,
              borderLeft: "5px solid #ff9c07",
            }}
          >
            <b>Highest Opportunities</b>
            {loading ? (
              <Space>
                <Skeleton.Input style={{ width: 150, height: 20 }} active />
              </Space>
            ) : (
              <div className="kpi">
                <b>
                  {highestOpp
                    ? "£ " +
                      highestOpp
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    : "N.A"}
                </b>
              </div>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              background: "#e8fffc",
              borderColor: "#9edad2",
              borderRadius: 5,
              borderLeft: "5px solid #25d5be",
            }}
          >
            <b>Reduced Churn Risk</b>
            {loading ? (
              <Space>
                <Skeleton.Input style={{ width: 150, height: 20 }} active />
              </Space>
            ) : (
              <div className="kpi">
                <b>
                  {reducedRisk
                    ? "£ " +
                      reducedRisk
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    : "N.A"}
                </b>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Cards;
