/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Card,
  Row,
  Col,
  DatePicker,
  Input,
  Space,
  Popover,
  Button,
  message,
  Spin,
  Select,
  Modal,
  Upload,
  Progress,
  Checkbox,
} from "antd";
import Finning from "../../assets/images/finning.png";
import FilterClose from "../../assets/images/filterClose.png";
import { useEffect, useState } from "react";
import "./filter.css";
import MultiSelect from "../Multi-Select/multiSelect";
import axios from "axios";
import { BASE_URL } from "../../constants/constants";
import {
  ExportOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import html2canvas from "html2canvas";

const { Option } = Select;
const { Search } = Input;
const { Dragger } = Upload;
const periodicityOptions = [
  {
    value: "1",
    label: "Year",
    disabled: false,
  },
  {
    value: "2",
    label: "Quarter",
    disabled: true,
  },
  {
    value: "3",
    label: "Month",
    disabled: true,
  },
];
const SubMenu = (props) => {
  const { history, highestFlightRisk } = props;
  const [visible, setVisibility] = useState(false);
  const [visisbleDownload, setvisibleDownload] = useState(false);
  const [excel, setExcel] = useState(false);
  const [screenshot, setScreenshot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromYear, setFromYear] = useState(
    localStorage.getItem("fromYear") || new Date().getFullYear().toString()
  );
  const [toYear, setToYear] = useState(
    new Date().setFullYear(
      localStorage.getItem("fromYear")
        ? Number(localStorage.getItem("fromYear")) + 1
        : new Date().getFullYear() + 1
    )
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [percentFileUpload, setPercentFileUpload] = useState(0);
  const [errorRowUrl, setErrorRowUrl] = useState("");

  // gets updated after api calls
  const [product, setProduct] = useState([]);
  const [division, setDivision] = useState([]);
  const [region, setRegion] = useState([]);
  const [store, setStore] = useState([]);
  const [churn, setChurn] = useState([]);
  const [market, setMarket] = useState([]);
  const [dealer, setDealer] = useState([
    { value: "All", label: "All" },
    { value: "Finning", label: "Finning" },
  ]);
  const [customer, setCustomer] = useState([]);
  // get updated when user clicks on filter button
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedChurn, setSelectedChurn] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState(["All", "Finning"]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);

  const handleVisibleChange = (visible) => {
    setVisibility(visible);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getRegion();
      await getDivision();
      await getStore();
      await getChurn();
      await getProduct();
      await getMarket();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      selectedProduct.length > 0 &&
      selectedProduct.length > 0 &&
      selectedRegion.length > 0 &&
      selectedStore.length > 0 &&
      selectedChurn.length > 0 &&
      selectedMarket.length > 0 &&
      loading === false
    ) {
      props.onHandleGetFlightRisk(dataPayload());
    }
  }, [loading]);

  useEffect(() => {
    if (highestFlightRisk.length > 0) {
      const resp = highestFlightRisk.map((item) => {
        return {
          value: item.customerNumber,
          label: item.customerNumber,
        };
      });
      const selectedCust = resp.map((prod) => prod.value);
      setCustomer([{ value: "All", label: "All" }, ...resp]);
      setSelectedCustomer(["All", ...selectedCust]);
    }
  }, [highestFlightRisk]);

  const getRegion = async () => {
    try {
      setLoading(true);
      const apiUrl = `${BASE_URL}/churnAnalysis/getRegionFilter`;

      await axios
        .get(apiUrl)
        .then((response) => {
          const resp = response?.data || "";
          const region = resp.map((prod) => {
            return {
              value: prod.new_region,
              label: prod.new_region,
            };
          });
          const selectedRegion = resp.map((prod) => prod.new_region);
          setRegion([{ value: "All", label: "All" }, ...region]);
          setSelectedRegion(["All", ...selectedRegion]);
          props.selectedRegion(selectedRegion);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getStore = async () => {
    try {
      const apiUrl = `${BASE_URL}/churnAnalysis/getStoreNameFilter`;

      await axios
        .get(apiUrl)
        .then((response) => {
          const resp = response?.data || "";
          if(props?.sendStoreResponse){
            props.sendStoreResponse(resp)
          }
          const store = resp.map((prod) => {
            return {
              value: prod.store_name,
              label: prod.store_name,
            };
          });
          const selectedStore = resp.map((prod) => prod.store_name);
          setStore([{ value: "All", label: "All" }, ...store]);
          setSelectedStore(["All", ...selectedStore]);
          props.selectedStore(selectedStore);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getDivision = async () => {
    try {
      const apiUrl = `${BASE_URL}/churnAnalysis/getIndustryFilter`;

      await axios
        .get(apiUrl)
        .then((response) => {
          const resp = response?.data || "";
          const division = resp.map((prod) => {
            return {
              value: prod.lookup_value,
              label: prod.lookup_value,
            };
          });
          const selectedDivision = resp.map((prod) => prod.lookup_value);

          setDivision([{ value: "All", label: "All" }, ...division]);
          setSelectedDivision(["All", ...selectedDivision]);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getChurn = async () => {
    try {
      const apiUrl = `${BASE_URL}/churnAnalysis/getChurnFilter`;

      await axios
        .get(apiUrl)
        .then((response) => {
          const resp = response?.data || "";
          const churn = resp.map((prod) => {
            return {
              value: prod.cluster_label,
              label: prod.cluster_label,
            };
          });
          const selectedChurn = resp.map((prod) => prod.cluster_label);
          setChurn([{ value: "All", label: "All" }, ...churn]);
          setSelectedChurn(["All", ...selectedChurn]);
          props.selectedChurn(selectedChurn);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getProduct = async () => {
    try {
      const apiUrl = `${BASE_URL}/churnAnalysis/getProductFilter`;

      await axios
        .get(apiUrl)
        .then((response) => {
          const resp = response?.data || "";
          const product = resp.map((prod) => {
            return {
              value: prod.lookup_value,
              label: prod.lookup_value,
            };
          });
          const selectedProduct = resp.map((prod) => prod.lookup_value);
          setProduct([{ value: "All", label: "All" }, ...product]);
          setSelectedProduct(["All", ...selectedProduct]);
          props.selectedProduct(selectedProduct);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const getMarket = async () => {
    try {
      const apiUrl = `${BASE_URL}/churnAnalysis/getMarketFilter`;

      await axios
        .get(apiUrl)
        .then((response) => {
          const resp = response?.data || "";
          const market = resp.map((prod) => {
            return {
              value: prod.market,
              label: prod.market,
            };
          });
          const selectedMarket = resp.map((prod) => prod.market);
          setMarket([{ value: "All", label: "All" }, ...market]);
          setSelectedMarket(["All", ...selectedMarket]);
          props.selectedMarket(selectedMarket);
          setLoading(false);
        })
        .catch((error = "") => {
          setLoading(false);
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const handlePeriodicity = (value) => {
    console.log(value);
  };

  const resetFilters = () => {
    setSelectedProduct([]);
    setSelectedDivision([]);
    setSelectedRegion([]);
    setSelectedStore([]);
    setSelectedChurn([]);
    setSelectedMarket([]);
    setSelectedDealer([]);
    setSelectedCustomer([]);
    message.success(
      {
        content: "Filters Cleared",
        style: {
          marginTop: "8vh",
        },
      },
      2
    );
  };

  const selectAllFilters = () => {
    setSelectedProduct([...product.map((prod) => prod.value)]);
    setSelectedDivision([...division.map((prod) => prod.value)]);
    setSelectedRegion([...region.map((prod) => prod.value)]);
    setSelectedStore([...store.map((prod) => prod.value)]);
    setSelectedChurn([...churn.map((prod) => prod.value)]);
    setSelectedMarket([...market.map((prod) => prod.value)]);
    setSelectedDealer([...dealer.map((prod) => prod.value)]);
    setSelectedCustomer([...customer.map((prod) => prod.value)]);
    message.success(
      {
        content: "All Filters Selected",
        style: {
          marginTop: "8vh",
        },
      },
      2
    );
  };

  const dataPayload = () => {
    const payload = {
      year: Number(fromYear),
      region: selectedRegion,
      dealer: selectedDivision,
      store: selectedStore,
      product: selectedProduct,
      churnLabel: selectedChurn,
      market: selectedMarket,
    };
    return payload;
  };

  const handleFromDate = (date, dateString) => {
    const d = new Date(dateString);
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    const finalDate = new Date(year + 1, month, day);
    setToYear(finalDate);
    setFromYear(dateString === "" ? null : dateString);
    const data = dataPayload();
    props.onHandleGetData({ ...data, year: Number(dateString),toYear:new Date(finalDate).getFullYear() });
    localStorage.setItem("fromYear", dateString);
  };

  const handleFlightRisk = () => {
    props.onHandleGetFlightRisk(dataPayload());
  };

  const handleModalVisibility = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setErrorRowUrl("");
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file.file);
    });
  };

  const handleUploadRequest = async (file) => {
    // const asyncFileData = await readFileAsync(file);
    try {
      const data = new FormData();
      data.append("file", file.file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      setPercentFileUpload(60);

      axios
        .post(`${BASE_URL}/churnAnalysis/importFile`, data, config)
        .then((response) => {
          setPercentFileUpload(80);
          const { signedUrl } = response.data;
          if (response.data && response.data.signedUrl) {
            setErrorRowUrl(signedUrl);
          } else {
            setErrorRowUrl("");
            message.success(
              {
                content: `${file.file.name} - Uploaded Successfully`,
                style: {
                  marginTop: "15vh",
                },
              },
              3000
            );
          }
          // const blob = new Blob([response.data], {
          //   type: "application/octet-stream",
          // });
          // const blobUrl = URL.createObjectURL(blob);
          // console.log(blobUrl);
          // const a = document.createElement("a");
          // a.href = blobUrl;
          // a.download = "Error_survey.xlsx";
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
          setPercentFileUpload(100);

          setTimeout(() => {
            setPercentFileUpload(0);
          }, 1000);
        });
    } catch (error) {
      setPercentFileUpload(0);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const handleDownloadSample = async () => {
    try {
      setPercentFileUpload(30);
      axios
        .get(`${BASE_URL}/churnAnalysis/getSampleFile`)
        .then((response) => {
          setPercentFileUpload(60);
          const sampleExcelUrl = response.data;

          for (let x = 0, ln = sampleExcelUrl.length; x < ln; x++) {
            setTimeout(
              function (y) {
                const a = document.createElement("a");
                a.href = sampleExcelUrl[y];
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setPercentFileUpload((prevState) => prevState + 10);
              },
              x * 1500,
              x
            );
          }

          setTimeout(() => {
            setPercentFileUpload(0);
          }, 6000);
        })
        .catch((error) => {
          setPercentFileUpload(0);
          message.error("Something went wrong");
        });
    } catch (error) {
      setPercentFileUpload(0);
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  const handleExport = async () => {
    if (excel && screenshot) {
      await setvisibleDownload(false);
      props.onHandleDownloadExcel();
      setTimeout(() => {
        html2canvas(document.body).then(function (canvas) {
          const a = document.createElement("a");
          a.download = "Screenshot.png";
          a.href = canvas.toDataURL();
          a.click();
        });
      }, 4000);
    } else if (excel) {
      props.onHandleDownloadExcel();
    } else if (screenshot) {
      await setvisibleDownload(false);
      setTimeout(() => {
        html2canvas(document.body).then(function (canvas) {
          const a = document.createElement("a");
          a.download = "Screenshot.png";
          a.href = canvas.toDataURL();
          a.click();
        });
      }, 2000);
    }
  };

  const filters = (
    <Row className="filteredRow">
      <Col span={11}>
        <label className="filterLabel">Region</label>
        <MultiSelect
          options={region}
          placeholder="Select Region"
          defaultValue={selectedRegion}
          onChangeFn={(event) => {
            setSelectedRegion(event);
          }}
        />
      </Col>
      <Col span={11}>
        <label className="filterLabel">Store</label>
        <MultiSelect
          options={store}
          placeholder="Select Store"
          defaultValue={selectedStore}
          onChangeFn={(event) => {
            setSelectedStore(event);
          }}
        />
      </Col>
      <Col span={11}>
        <label className="filterLabel">Division</label>
        <MultiSelect
          options={division}
          placeholder="Select Division"
          defaultValue={selectedDivision}
          onChangeFn={(event) => {
            setSelectedDivision(event);
          }}
        />
      </Col>
      <Col span={11}>
        <label className="filterLabel">Churn</label>
        <MultiSelect
          options={churn}
          placeholder="Select Churn"
          defaultValue={selectedChurn}
          onChangeFn={(event) => {
            setSelectedChurn(event);
          }}
        />
      </Col>
      <Col span={11}>
        <label className="filterLabel">Products</label>
        <MultiSelect
          options={product}
          placeholder="Select Product"
          defaultValue={selectedProduct}
          onChangeFn={(event) => setSelectedProduct(event)}
        />
      </Col>
      {
        history?.header ==="View Individual Customer" ?
      <Col span={11}>
        <label className="filterLabel">Dealer</label>
        <MultiSelect
          options={dealer}
          placeholder="Select Dealer"
          defaultValue={selectedDealer}
          onChangeFn={(event) => setSelectedDealer(event)}
        />
      </Col>
      : null
}
      <Col span={11}>
        <label className="filterLabel">Market</label>
        <MultiSelect
          options={market}
          placeholder="Select Market"
          defaultValue={selectedMarket}
          onChangeFn={(event) => {
            setSelectedMarket(event);
          }}
        />
      </Col>
      {
        history?.header ==="View Individual Customer" ?
      
      <Col span={11}>
        <label className="filterLabel">Cust Segment</label>
        <MultiSelect
          options={customer}
          placeholder="Select Segment"
          defaultValue={selectedCustomer}
          onChangeFn={(e) => setSelectedCustomer(e)}
        />
      </Col>
      : null
}
      <Col span={24} className="filterButton">
        <Button className="button" onClick={selectAllFilters}>
          Select All
        </Button>
        <Button className="button" onClick={resetFilters}>
          Reset All
        </Button>
        <Button
          style={{ backgroundColor: "rgb(10, 111, 184)", color: "white" }}
          className="button"
          onClick={handleFlightRisk}
        >
          Apply Filter
        </Button>
      </Col>
    </Row>
  );

  const spinIcon = <LoadingOutlined style={{ fontSize: 25 }} spin />;

  return (
    <Card className="card-submenu">
      <Row align="middle">
        <Col span={7}>
          <Space>
            {/* <b>Dashboard</b> */}
            {/* <img src={Finning} alt="Finning" /> */}
            <b>360 CX {props.header}</b>
          </Space>
        </Col>
        <Col span={17}>
          <Modal
            title="Download Excel or Take a screenshot"
            visible={visisbleDownload}
            width={400}
            onCancel = {() => setvisibleDownload(false)}
            footer={[
              <Button key="back" onClick={() => setvisibleDownload(false)}>
                CANCEL
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={!excel && !screenshot}
                onClick={handleExport}
              >
                OK
              </Button>,
            ]}
          >
            <Checkbox
              value={excel}
              onChange={(e) => setExcel(e.target.checked)}
            >
              Excel
            </Checkbox>
            <Checkbox
              value={screenshot}
              onChange={(e) => setScreenshot(e.target.checked)}
            >
              Screenshot
            </Checkbox>
          </Modal>
          <Modal
            title="Upload File"
            visible={modalVisible}
            maskClosable={false}
            // onOk={handleOk}
            footer={[]}
            onCancel={handleCancel}
            destroyOnClose
          >
            <div
              className="draggerbox"
              style={{ padding: "0px 30px 0px 30px" }}
            >
              <Dragger
                // disabled={uploadInProgress}
                name="file"
                customRequest={handleUploadRequest}
                fileList={[]}
                accept=".xlsx"
              >
                <PaperClipOutlined style={{ fontSize: "20px" }} />
                <a style={{ fontWeight: "600", color: "#5b6cc3" }}>
                  {" "}
                  Add File{" "}
                </a>
                <p className="draggertitle">or Drop files here</p>
                <p className="fileType">Only .XLS files allowed to upload</p>
              </Dragger>
              {percentFileUpload <= 0 ? null : (
                <div style={{ padding: "0px 30px 0px 30px" }}>
                  <Progress
                    percent={percentFileUpload}
                    status="active"
                    style={{ margin: "0px 8px" }}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <a
                  style={{
                    fontWeight: "600",
                    color: "#5b6cc3",
                  }}
                  onClick={handleDownloadSample}
                >
                  {" "}
                  Click Here
                </a>
                <p className="draggertitle">
                  to download the sample file for upload
                </p>
              </div>
              {errorRowUrl !== "" && (
                <div className="errorRows">
                  <p className="draggertitle">Upload completed, </p>
                  <a
                    style={{ fontWeight: "600", color: "#5b6cc3" }}
                    href={errorRowUrl}
                  >
                    {" "}
                    CLICK HERE
                  </a>{" "}
                  <p className="draggertitle"> to download the error rows</p>
                </div>
              )}
            </div>
          </Modal>
          <Row justify="end">
            <Space>
              <Select
                style={{ width: 150 }}
                defaultValue={"1"}
                placeholder="Periodicity"
                onChange={handlePeriodicity}
              >
                {periodicityOptions.map((option) => (
                  <Option
                    key={option.value}
                    disabled={option.disabled}
                    value={option.value}
                  >
                    {option.label}
                  </Option>
                ))}
              </Select>
              <DatePicker
                defaultValue={moment(fromYear)}
                value={moment(fromYear)}
                onChange={handleFromDate}
                allowClear={false}
                picker="year"
                disabled={loading}
              />
              <DatePicker value={moment(toYear)} picker="year" disabled />
              <Popover
                overlayStyle={{
                  width: "34vw",
                }}
                placement="bottomRight"
                content={filters}
                title="Apply Filter"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
              >
                {loading ? (
                  <Spin
                    className="loader"
                    spinning={loading}
                    indicator={spinIcon}
                  />
                ) : (
                  <img
                    src={FilterClose}
                    style={{ cursor: "pointer" }}
                    alt="FilterClose"
                  />
                )}
              </Popover>
              {/* <Search
                placeholder="Search by Customer"
                style={{ minWidth: 300 }}
                onSearch={props.onHandleSearchCustomer}
                allowClear
              /> */}
              {props.extraButton}
              <Col span={2}>
                <Button
                  // className={Styles.btntext}
                  onClick={handleModalVisibility}
                  // disabled={uploadInProgress
                >
                  <UploadOutlined
                    style={{
                      fontSize: "22px",
                      color: "#717ebd",
                    }}
                  />
                </Button>
              </Col>
              <Col span={2}>
                <Button
                  // className={Styles.btntext}
                  onClick={() => setvisibleDownload(true)}
                  // disabled={uploadInProgress
                >
                  <ExportOutlined
                    style={{ fontSize: "22px", color: "#0a6fb8" }}
                  />
                </Button>
              </Col>
            </Space>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default SubMenu;
