import React, { useEffect, useState } from "react";
import { Row, Select, Slider, Spin } from "antd";
import AnyChart from "anychart-react";
import "./layout.css";

const { Option } = Select;
// const complexSettings = {
//   height: 250,
//   type: "quadrant",
//   data,
//   title: "",
// };

const marksVertical = {
  0: {
    style: {
      color: "#fff",
    },
    label: (
      <div>
        <div>Low</div>
        {/* <div>Low</div> */}
      </div>
    ),
  },
  50: {
    style: {
      color: "#fff",
      left: -20,
      transform: "rotate(270deg)",
    },
    label: <span>Churn Risk %</span>,
  },
  100: {
    style: {
      color: "#fff",
    },
    label: <span>High</span>,
  },
};

const colotByOptions = [
  {
    value: "1",
    label: "Region",
    disabled: false,
  },
  {
    value: "2",
    label: "Product",
    disabled: false,
  },
  {
    value: "3",
    label: "Store",
    disabled: false,
  },
  {
    value: "4",
    label: "Market",
    disabled: false,
  },
  {
    value: "5",
    label: "Cluster Label",
    disabled: true,
  },
];

const Charts = (props) => {
  const [tableData, setTableData] = useState([]);
  const [tableDataTemp, setTableDataTemp] = useState([]);
  const [maxScale, setMaxScale] = useState(0);
  const [minScale, setMinScale] = useState(0);
  const [maxYSliderScale, setMaxYSliderScale] = useState(110);
  const [minYSliderScale, setMinYSliderScale] = useState(0);
  const [maxSliderScale, setMaxSliderScale] = useState(0);
  const [minSliderScale, setMinSliderScale] = useState(0);
  const [maxRevenueScale, setMaxRevenueScale] = useState([0, 0]);
  const { highestFlightRisk, loading } = props;
  const [marks, setMarks] = useState({});
  const [churnRisk, setChurnRisk] = useState([0, 100]);
  const [complexSettings, setComplexSettings] = useState({
    height: 270,
    type: "quadrant",
    // padding: 10,
    margin: 10,
    title: "",
    crossing: {
      stroke: "2 rgb(25, 118, 210)",
    },
    quarters: {
      rightTop: {
        fill: "#fff",
      },
      leftBottom: {
        fill: "#fff",
      },
    },

    tooltip: {
      enabled: true,
      titleFormat: "Customer Name: {%name}",
      format:
        "Churn Risk Current Year: {%y} % \n\n Churn Risk Previous Year: {%churnRiskPreviousYear} % \n\n Revenue: £ {%revenue}",
    },
    // labels: {
    //   format: "{%x}",
    //   fontSize: "12px",
    //   fontColor: "black",
    // },
    format: "{%x}",
    yScale: {
      minimum: 0,
      maximum: 120,
      ticks: {
        interval: 10,
      },
    },
    yAxis: [
      2,
      {
        orientation: "left",
        // enabled: true,
        // ticks: { stroke: "gray" },
        // labels: { enabled: true, fontColor: "black" },
      },
    ],
    xGrid: { enabled: true, stroke: { color: "gray", dash: "3 5" } },
    yGrid: { enabled: true, stroke: { color: "gray", dash: "3 5" } },
    xAxis: [
      2,
      {
        orientation: "bottom",
      },
    ],
  });

  useEffect(() => {
    if (highestFlightRisk.length > 0) {
      const data = highestFlightRisk.map((item) => ({
        x: Number(item.revenue),
        y: Number(item.churnRiskCurrentYear),
        name: item.customerNumber,
        churnRiskPreviousYear: item.churnRiskPreviousYear,
        region: item.region,
        market: item.market,
        product: item.product,
        store: item.store,
        revenue: Number(item.revenue).toLocaleString(),
        normal: {
          fill: `${item.color ? item.color : "#0a6fb8"} 0.6`,
          stroke: `${item.color ? item.color : "#0a6fb8"}`,
          markerSize: 5,
        },
      }));

      const max = Math.max(...data.map((item) => item.x)) + 10000;
      const min = Math.min(...data.map((item) => item.x)) - 10000;

      setMinScale(min);
      setMinSliderScale(min);
      setMaxScale(max);
      setMaxSliderScale(max);
      setMaxRevenueScale([min, max]);
      setTableData(data);
      setTableDataTemp(data);
    } else {
      setTableData([]);
      setTableDataTemp([]);
    }
  }, [highestFlightRisk]);

  useEffect(() => {
    if (maxSliderScale && minSliderScale) {
      const marks = {
        [`${minSliderScale}`]: {
          style: {
            color: "#fff",
          },
          label: (
            <span style={{ position: "relative", left: 40 }}>
              £ {Number(minSliderScale).toLocaleString()}
            </span>
          ),
        },
        // 0: {
        //   style: {
        //     color: "#fff",
        //   },
        //   label: <span>£ 0 K</span>,
        // },
        [`${maxSliderScale / 2}`]: {
          style: {
            color: "#fff",
          },
          label: <span>Total Revenue</span>,
        },
        [`${maxSliderScale}`]: {
          style: {
            color: "#fff",
          },
          label: (
            <span style={{ position: "relative", right: 30 }}>
              £ {Number(maxSliderScale).toLocaleString()} Mn
            </span>
          ),
        },
      };

      setMarks(marks);
    }
  }, [maxSliderScale, minSliderScale]);

  const handleChangeColor = async (event) => {
    let tooltip = {};
    if (event === "1") {
      tooltip = {
        enabled: true,
        titleFormat: "Customer Name: {%name}",
        format:
          "Churn Risk Current Year: {%y} % \n\n Churn Risk Previous Year: {%churnRiskPreviousYear} % \n\n Revenue: £ {%revenue} \n\n Region: {%region}",
      };
    } else if (event === "2") {
      tooltip = {
        enabled: true,
        titleFormat: "Customer Name: {%name}",
        format:
          "Churn Risk Current Year: {%y} % \n\n Churn Risk Previous Year: {%churnRiskPreviousYear} % \n\n Revenue: £ {%revenue} \n\n Product: {%product}",
      };
    } else if (event === "2") {
      tooltip = {
        enabled: true,
        titleFormat: "Customer Name: {%name}",
        format:
          "Churn Risk Current Year: {%y} % \n\n Churn Risk Previous Year: {%churnRiskPreviousYear} % \n\n Revenue: £ {%revenue} \n\n Product: {%product}",
      };
    } else if (event === "3") {
      tooltip = {
        enabled: true,
        titleFormat: "Customer Name: {%name}",
        format:
          "Churn Risk Current Year: {%y} % \n\n Churn Risk Previous Year: {%churnRiskPreviousYear} % \n\n Revenue: £ {%revenue} \n\n Store: {%store}",
      };
    } else if (event === "4") {
      tooltip = {
        enabled: true,
        titleFormat: "Customer Name: {%name}",
        format:
          "Churn Risk Current Year: {%y} % \n\n Churn Risk Previous Year: {%churnRiskPreviousYear} % \n\n Revenue: £ {%revenue} \n\n Market: {%market}",
      };
    } else {
      tooltip = {
        enabled: true,
        titleFormat: "Customer Name: {%name}",
        format:
          `Churn Risk Current Year: {%y} % \n\n Churn Risk Previous Year: {%churnRiskPreviousYear} % \n\n Revenue: £ {%revenue}`,
      };
    }
    await setComplexSettings({ ...complexSettings, tooltip: tooltip });
    props.onHandleColorBy(event);
  };

  const handleChurnRisk = (event) => {
    const filteredDate = tableDataTemp.filter(
      (item) =>
        item.y >= event[0] &&
        item.y <= event[1] &&
        item.x >= maxRevenueScale[0] &&
        item.x <= maxRevenueScale[1]
    );
    setTableData(filteredDate);
  };

  const handleRevenue = (event) => {
    const filteredDate = tableDataTemp.filter(
      (item) =>
        item.x >= event[0] &&
        item.x <= event[1] &&
        item.y >= churnRisk[0] &&
        item.y <= churnRisk[1]
    );
    setTableData(filteredDate);
    setMinScale(event[0]);
  };

  return (
    <div className="chart-class">
      <Row justify="space-between" align="middle">
        <div>
          <b>Likely Revenue Risk</b>
        </div>
        <div>
          <Select
            style={{ width: 150 }}
            allowClear
            placeholder="Color By"
            onChange={(value) => handleChangeColor(value)}
          >
            {colotByOptions.map((option) => (
              <Option disabled={option.disabled} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </div>
      </Row>
      <Spin spinning={loading} tip="Loading...">
        <div className="chart-div">
          <Row>
            <div className="vertical-slider">
              <Slider
                vertical
                range
                max={110}
                min={0}
                defaultValue={churnRisk}
                marks={marksVertical}
                onChange={(event) => {
                  setMaxYSliderScale(event[1]);
                  setMinYSliderScale(event[0]);
                  setChurnRisk(event);
                }}
                onAfterChange={(value) => handleChurnRisk(value)}
              />
            </div>

            <AnyChart
              {...complexSettings}
              data={tableData}
              xScale={{ minimum: minScale, maximum: maxScale }}
              yScale={{ minimum: minYSliderScale, maximum: maxYSliderScale }}
            />
          </Row>

          <div className="horizontal-slider">
            <Slider
            tipFormatter = {e => `£ ${Number(e).toLocaleString()}`}
              range
              min={minSliderScale}
              max={maxSliderScale}
              value={maxRevenueScale}
              marks={marks}
              onChange={(event) => {
                setMinScale(event[0]);
                setMaxScale(event[1]);
                setMaxRevenueScale(event);
              }}
              onAfterChange={(value) => handleRevenue(value)}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Charts;
