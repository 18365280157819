/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { Form, Input, Button, Alert, Spin, Row, Col, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import auth0Client from "../../auth0/Auth";
import auth from "../../utils/auth";
import Styles from "../../styles/auth.module.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);

const messageStyle = { textAlign: "center", fontWeight: 600 };

const layout = {
  labelCol: { span: 24 },
};

const RegisterFooter = (props) => (
  <div className="login-footer">
    <h4>Already have an account? Log in</h4>
    <a onClick={() => props.history.push("/login")} className={Styles.register}>
      {" "}
      Log in
    </a>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loggingIn: false,
    };
  }

  handleSubmit = async (e) => {
    const { emailId, password } = e;
    auth.removeToken();
    this.setState({ errorMessage: null, loggingIn: true });
    try {
      const response = await auth0Client.manualLogin(emailId, password);
      this.setState({
        errorMessage: response || "Invalid credentials",
        loggingIn: false,
      });
    } catch (error) {
      this.setState({ loggingIn: false });
      throw new Error({ error });
    }
  };

  onChange = () => {
    this.setState({ errorMessage: null });
  };

  render() {
    const { errorMessage, loggingIn } = this.state;
    console.log(this.props);
    return (
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100%",
        }}
      >
        <div
          className={Styles.loginForm}
          style={{
            padding: "30px 45px",
            width: "100%",
          }}
        >
          <label className={Styles.mainLabelLogin}>Register</label>
          {errorMessage && (
            <Alert message={errorMessage} type="error" style={messageStyle} />
          )}
          <Form
            initialValues={{
              remember: true,
            }}
            {...layout}
            requiredMark={"optional"}
            name="login"
            className="registerForm"
            onFinish={this.handleSubmit}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  name="fName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                  ]}
                  onChange={this.onChange}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name",
                    },
                  ]}
                  onChange={this.onChange}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="emailId"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter valid Email id",
                    },
                  ]}
                  onChange={this.onChange}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter title",
                    },
                  ]}
                  onChange={this.onChange}
                >
                  <Select placeholder="Title" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Business unit"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Business unit",
                    },
                  ]}
                  onChange={this.onChange}
                >
                  <Select placeholder="Business unit" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Region"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Region",
                    },
                  ]}
                  onChange={this.onChange}
                >
                  <Select placeholder="Region" />
                </Form.Item>
              </Col>
            </Row> */}
            {/* 
            <Row>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Country"
                    }
                  ]}
                  onChange={this.onChange}
                >
                  <Select placeholder="Country" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Dealer"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Dealer"
                    }
                  ]}
                  onChange={this.onChange}
                >
                  <Select placeholder="Dealer" />
                </Form.Item>
              </Col>
            </Row> */}
            {/* 
            <Row>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Store"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Store"
                    }
                  ]}
                  onChange={this.onChange}
                >
                  <Select placeholder="Store" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="title"
                  label="Privileges"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Privileges"
                    }
                  ]}
                  onChange={this.onChange}
                >
                  <Select placeholder="Privileges" />
                </Form.Item>
              </Col>
            </Row> */}

            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: 5 }}
              className={Styles.loginFormButton}
            >
              {loggingIn ? (
                <Spin
                  indicator={antIcon}
                  size="large"
                  style={{ fontSize: 20 }}
                />
              ) : (
                "Register"
              )}
            </Button>
          </Form>
          <RegisterFooter {...this.props} />
        </div>
      </Row>
    );
  }
}

export default LoginForm;
