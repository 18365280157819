import auth from "../utils/auth";

const { id: USER_ID } = auth.get("userDetails") || {};

const ORG_ID = Number(auth.get("selectedHDC"));

const ROLE_ID = auth.getRoleId() || null;

const tempUrl = "https://cors-anywhere.herokuapp.com/";

const DEV_IP = "https://360cx-dev.aq4.io/api/";

const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const EXCEL_FILE_EXTENSION = ".xlsx";

const BASE_URL = `${DEV_IP}`;

const DASHBOARD_PAGE_ROUTE = "/dashboard";

const ADMIN_PAGE_ROUTE = "/configurations";

const getToken = () => auth.get("apiToken");
// const getToken = () => {
//   return "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1c2VyMkBnbWFpbC5jb20iLCJleHAiOjE2Mzk3MjA0NTJ9.jMqPXtAZO8OB360K_-G5h13mzPaKXzG4X9qOSHxFDq0";
// };
export {
  EXCEL_FILE_TYPE,
  EXCEL_FILE_EXTENSION,
  BASE_URL,
  DASHBOARD_PAGE_ROUTE,
  ADMIN_PAGE_ROUTE,
  USER_ID,
  ORG_ID,
  ROLE_ID,
  getToken,
};
